/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { SVGRender } from '../../../helper/image';

const settings = {
	dots: false,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	autoplay: true,
	autoplaySpeed: 2000,
	pauseOnHover: true,
	arrows: false,
};

const SimpleSlider = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const customeSlider = useRef();
	const gotoNext = () => {
		customeSlider.current.slickNext();
	};

	const gotoPrev = () => {
		customeSlider.current.slickPrev();
	};

	const goTo = index => {
		customeSlider.current.slickGoTo(index);
	};

	return (
		<div className="slider-wrapper">
			<Slider
				{...settings}
				beforeChange={(current, next) => {
					setSelectedTab(next);
				}}
				ref={customeSlider}
			>
				<div>
					<div className="listcl-wrapper">
						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%205.png?format=webp&q=100"
								alt="BankIndo"
							/>
						</div>
						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%204.png?format=webp&q=100"
								alt="Manulife"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/kino.png?format=webp&q=100"
								alt="Kino"
								className="mxh-28"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%202.png?format=webp&q=100"
								alt="SharingHappines"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%201.png?format=webp&q=100?format=webp"
								alt="Bajuyuli"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2015.png?format=webp&q=100"
								alt="Sbmitb"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2014.png?format=webp&q=100"
								alt="Borongdong"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2013.png?format=webp&q=100"
								alt="Eden"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2012.png?format=webp&q=100"
								alt="Solusindo"
							/>
						</div>
					</div>
				</div>

				<div>
					<div className="listcl-wrapper">
						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2011.png?format=webp&q=100"
								alt="Baraya"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2010.png?format=webp&q=100"
								alt="Isuzu"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2020.png?format=webp&q=100"
								alt="Tunasbmw"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%209.png?format=webp&q=100"
								alt="Knito"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%208.png?format=webp&q=100"
								alt="Halofina"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/glowlabs.png?format=webp&q=100"
								className="mxh-19"
								alt="glowlabs"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%207.png?format=webp&q=100"
								alt="Chatat"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%206.png?format=webp&q=100"
								alt="Rscibubur"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/rspermatadepok.png?format=webp&q=100"
								alt="Rspermatadepok"
							/>
						</div>
					</div>
				</div>

				<div>
					<div className="listcl-wrapper">
						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2021.png?format=webp&q=100"
								alt="Tafsir"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2022.png?format=webp&q=100"
								alt="Baznas"
							/>
						</div>

						<div className="listcl">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2018.png?format=webp&q=100"
								alt="Surface"
							/>
						</div>

						<div className="listcl w-50">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2019.png?format=webp&q=100"
								alt="Ymbri"
								className="mxh-45"
							/>
						</div>

						<div className="listcl w-50">
							<img
								src="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2016.png?format=webp&q=100"
								alt="Itb"
								className="mxh-45"
							/>
						</div>
					</div>
				</div>
			</Slider>
			<div className="bottomTestimony">
				<div className="btnWrapper">
					<button type="button" onClick={() => gotoPrev()}>
						{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/ico-arrow-orange-border.svg')}
					</button>
					<button type="button" onClick={() => gotoNext()}>
						{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/ico-arrow-orange-border.svg')}
					</button>
				</div>
				<div className="listDots">
					<ul>
						<li
							onClick={() => {
								goTo(0);
							}}
							onKeyDown={() => {
								goTo(0);
							}}
							className={selectedTab === 0 ? 'active' : ''}
						/>
						<li
							onClick={() => {
								goTo(1);
							}}
							onKeyDown={() => {
								goTo(1);
							}}
							className={selectedTab === 1 ? 'active' : ''}
						/>
						<li
							onClick={() => {
								goTo(2);
							}}
							onKeyDown={() => {
								goTo(2);
							}}
							className={selectedTab === 2 ? 'active' : ''}
						/>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SimpleSlider;
