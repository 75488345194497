import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import 'lazysizes';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fontsource/nunito';

import withIntl from '../../../helper/hoc/withIntl';
import withDefautlHoc from '../../_hoc/global';
import BannerPromo from './Banner';
import ContentPromo from './Content';
import ButtonLink from '../../atoms/Button/Link';
import Paragraph from '../../atoms/Label/Paragraph/Clear';
import { Wrapper } from './style';

const Promo3Month = ({ enPrefix }) => {
	const intl = useIntl();
	const [isExpire, setExpire] = useState(false);

	const navigateToFormSignUp = () => {
		let currentUrl = '';
		if (typeof window !== 'undefined') {
			currentUrl = window.location.href;
		}
		if (enPrefix) {
			navigate(`/en/form/signup?fromUrl=${currentUrl}`);
		} else {
			navigate(`/form/signup?lang=id&fromUrl=${currentUrl}`);
		}
	};

	return (
		<Wrapper id="promo-3-months">
			<div className="cLightChatbiz">
				<div className="row">
					<nav className="navbar fixed-top navbar-light bg-white">
						<div className="container">
							<a className="navbar-brand" href="/">
								<img
									src="https://seiturju.sirv.com/Chatbiz/homepage/ChatbizFooter.svg"
									alt="Chatbiz Logo"
								/>
							</a>
							{!isExpire && (
								<ButtonLink onClick={navigateToFormSignUp}>
									<Paragraph>{intl.formatMessage({ id: `promo_3_month.menu.button` })}</Paragraph>
								</ButtonLink>
							)}
						</div>
					</nav>
				</div>
			</div>
			<BannerPromo
				onSetExpire={expire => {
					setExpire(expire);
				}}
				isExpire={isExpire}
			/>
			<ContentPromo enPrefix={enPrefix} isExpire={isExpire} onRegister={navigateToFormSignUp} />
		</Wrapper>
	);
};

Promo3Month.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default withIntl(withDefautlHoc(Promo3Month));
