import styled from 'styled-components';
import { Large } from '../../atoms/_Variable/var';

export const CUSTOM_TABLET = 1165;
export const CUSTOM_MOBILE = 768;
export const CUSTOM_LARGE = 1500;

export const Wrapper = styled.section`
	&#promo-3-months {
		background: #f9fbff;
		.navbar {
			.btnLinkDefault {
				display: none;
				@media screen and (max-width: ${CUSTOM_TABLET}px) {
					display: block;
					.pDefault {
						font-size: 0.813rem;
						font-weight: bold;
					}
				}
			}
		}

		.cLightChatbiz {
			background: #f9fbff;
		}
		.cblueChatbiz {
			background: #0664be;
		}

		.whiteColor {
			color: #fff !important;
		}
		#contentMain {
			box-shadow: 0px 1px 16px 0px #0045ad26;
			border-radius: 16px;
			margin-top: -2.85em;
			@media screen and (max-width: ${CUSTOM_MOBILE}px) {
				flex-direction: column;
				&.p-5 {
					padding: 2.063rem 1.25rem !important;
				}
			}

			.content__cta {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				@media screen and (max-width: ${CUSTOM_TABLET}px) {
					flex-direction: column;
				}
				@media screen and (max-width: ${CUSTOM_MOBILE}px) {
					align-items: center;
				}
				&-left {
					.pDefault {
						margin-top: 0.5rem;
						@media screen and (max-width: ${CUSTOM_MOBILE}px) {
							margin-top: 1.125rem;
						}
					}
					@media screen and (max-width: ${CUSTOM_MOBILE}px) {
						.h2Default,
						.pDefault {
							text-align: center;
						}
					}
				}
				&-right {
					@media screen and (max-width: ${CUSTOM_TABLET}px) {
						margin-top: 1.125rem;
					}
					.btnPrimaryDefault {
						padding: 1.063rem 2rem !important;
						.pDefault {
							font-size: 0.813rem !important;
						}
					}
				}
			}

			.content {
				display: flex;
				margin-top: 3rem;
				@media screen and (max-width: ${CUSTOM_TABLET}px) {
					flex-direction: column;
					margin-top: 3.5rem;
				}
				&__left {
					width: 50%;
					@media screen and (max-width: 1366px) {
						width: 53%;
					}
					@media screen and (max-width: 1342px) {
						width: 50%;
					}
					@media screen and (max-width: 1237px) {
						width: 45%;
					}
					@media screen and (max-width: ${CUSTOM_TABLET}px) {
						width: 100%;
					}
					@media screen and (min-width: ${Large}) {
						width: 53%;
					}
					.list-wrapper {
						display: flex;
						@media screen and (max-width: ${CUSTOM_MOBILE}px) {
							flex-direction: column;
						}
						.list-unstyled {
							li {
								display: flex;
								align-items: flex-start;
								&:not(:first-child) {
									margin-top: 1.313rem;
									@media screen and (max-width: ${CUSTOM_MOBILE}px) {
										margin-top: 1rem;
									}
								}
							}
						}
						&__right {
							margin-left: 1.625rem;
							@media screen and (max-width: ${CUSTOM_MOBILE}px) {
								margin-left: 0;
							}
						}
					}
				}

				&__right {
					padding-left: 5rem;
					width: 50%;
					@media screen and (max-width: 1366px) {
						width: 47%;
					}
					@media screen and (max-width: 1342px) {
						width: 50%;
					}
					@media screen and (max-width: 1237px) {
						width: 55%;
					}
					@media screen and (max-width: ${CUSTOM_TABLET}px) {
						width: 100%;
						padding-left: 0;
						margin-top: 3.375rem;
					}
					@media screen and (min-width: ${Large}) {
						width: 47%;
					}
				}
			}
		}

		#countdown {
			@media screen and (max-width: ${CUSTOM_TABLET}px) {
				margin-top: 1.625rem;
			}
		}

		.supergraphicOverlay {
			background-image: url(https://seiturju.sirv.com/Chatbiz/v4/quarter-circle3.svg);
			background-repeat: no-repeat;
			background-size: 300px 300px;
			background-position: right -25px top 0px;
		}

		.bgCoundownt {
			background-color: rgba(255, 255, 255, 0.15);
			border-radius: 6px;
			&.expired {
				min-width: 217px;
				padding: 0.2rem 0;
			}
			&__expiretitle {
				font-family: Montserrat, sans-serif;
				font-style: normal;
				font-weight: bold;
				font-size: 1.625rem;
				line-height: 150%;
				color: #ffffff;
				font-weight: bold;
				text-align: center;
			}

			&__expiresub {
				color: #ffffff;
				text-align: center;
			}

			.card-body {
				padding: 0.813rem;
				min-width: 79px;
				min-height: 76px;
				@media screen and (max-width: 379px) {
					padding: 0.813rem;
					min-width: 75px;
					min-height: 75px;
				}

				@media screen and (max-width: 367px) {
					padding: 0.2rem 0.813rem;
					min-width: auto;
					min-height: auto;
				}
				.card-title {
					margin-bottom: 0;
					.h3 {
						font-family: Montserrat, sans-serif;
						font-style: normal;
						font-weight: bold;
						font-size: 1.625rem;
						line-height: 150%;
					}
				}
				.h3Default {
					color: #ffffff;
					margin-bottom: 0.188rem;
				}
				.pDefault {
					color: #ffffff;
				}
			}
		}

		.slider-wrapper {
			margin-top: 1.875rem;
		}

		.listcl-wrapper {
			display: flex;
			flex-wrap: wrap;
		}
		.listcl {
			width: 33.333%;
			display: flex;
			padding: 1rem 0.8rem;
			align-items: center;
			justify-content: center;
			&.w-50 {
				width: 50%;
			}

			@media screen and (max-width: ${CUSTOM_MOBILE}px) {
				padding: 0.5rem 0.4rem;
			}
			img,
			picture {
				width: 100%;
				filter: grayscale(100%);
				-webkit-backface-visibility: hidden;
				-ms-transform: translateZ(0);
				-webkit-transform: translateZ(0);
				transform: translateZ(0);
				image-rendering: -moz-crisp-edges;
				image-rendering: -o-crisp-edges;
				image-rendering: -webkit-optimize-contrast;
				image-rendering: crisp-edges;
				-ms-interpolation-mode: nearest-neighbor;
				&:hover {
					filter: grayscale(0%);
				}
				&.mxh-28 {
					width: auto;
					max-height: 28.42px;
				}
				&.mxh-45 {
					width: auto;
					max-height: 45px;
				}
			}
		}

		.listbf {
			height: 3.8rem;
			@media screen and (max-width: ${CUSTOM_MOBILE}px) {
				height: 3.125rem;
			}
			@media screen and (min-width: ${Large}) {
				height: 5rem;
			}
		}

		#bf-down {
			display: flex;
			align-items: center;
			@media screen and (max-width: 1342px) {
				flex-direction: column;
				align-items: flex-start;
			}
			@media screen and (max-width: ${CUSTOM_TABLET}px) {
				flex-direction: row;
				align-items: center;
			}
			@media screen and (max-width: 853px) {
				flex-direction: column-reverse;
				justify-content: flex-start;
				align-items: flex-start;
			}
			@media screen and (min-width: ${Large}) {
				margin-top: 2.438rem;
			}
			.bf-down {
				&__left {
					width: 55%;
					display: flex;
					@media screen and (max-width: 1342px) {
						width: 100%;
						margin-top: 2.438rem;
					}
					@media screen and (max-width: ${CUSTOM_TABLET}px) {
						width: auto;
						margin-top: 0;
					}
					@media screen and (min-width: ${CUSTOM_LARGE}px) {
						width: 60%;
					}
				}
				&__right {
					width: 45%;

					@media screen and (max-width: 1342px) {
						width: 100%;
						margin-top: 1.188rem;
					}
					@media screen and (max-width: ${CUSTOM_TABLET}px) {
						width: auto;
						margin-top: 0;
						display: flex;
						align-items: center;
						margin-left: 1.75rem;
					}
					@media screen and (max-width: 853px) {
						margin-top: 2rem;
						margin-bottom: 0.875rem;
						margin-left: 0;
					}
					@media screen and (min-width: ${CUSTOM_LARGE}px) {
						width: 40%;
					}
				}
			}
		}

		.sclist {
			/* margin: 1rem -0.2rem; */
			max-width: 46.11px;
			width: 100%;
			/* margin-right: 1.21rem; */

			@media screen and (max-width: 370px) {
				max-width: 42px;
			}
			@media screen and (min-width: ${Large}) {
				max-width: 42px;
			}
			&:not(:last-child) {
				margin-right: 1.188rem;
				@media screen and (max-width: ${CUSTOM_MOBILE}px) {
					margin-right: 1rem;
				}
			}
		}

		/* Css bawaan bootstrap tapi gak work ya udahlah dibuat lagi aja*/

		.float-right {
			float: right;
		}

		.mt-n1 {
			margin-top: -0.25rem !important;
		}

		/* Redesign */
		/* p {
			font-family: 'Nunito', Arial, Helvetica, sans-serif;
			font-size: 1rem;
			font-style: normal;
			line-height: 1.8em;
			letter-spacing: 0rem;
			margin-bottom: 0;
		}

		h1 {
			font-family: 'Montserrat', Arial, Helvetica, sans-serif;
			font-style: normal;
			font-weight: 700;
			line-height: 38px;
			letter-spacing: 0rem;
			text-align: left;
		}

		h2,
		h3 {
			font-size: 1.75rem;
		} */
		.navbar-brand {
			margin-left: -0.5em !important;
			padding: 0.4em 0 0.4em;
		}

		.medmedia {
			img {
				max-width: 28px;
			}
		}

		.bottomTestimony {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 3.313rem;

			.listDots {
				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					display: flex;

					li {
						width: 14px;
						height: 14px;
						background: #bbdde6;
						opacity: 0.4;
						border-radius: 50px;
						margin-right: 1rem;
						cursor: pointer;
						&.active {
							background: #ff8f1c;
							opacity: 1;
						}
					}
				}
			}
			.btnWrapper {
				button {
					border: none;
					background-color: transparent;
					max-width: 40px;
					@media screen and (max-width: ${CUSTOM_MOBILE}px) {
						max-width: 30px;
					}
					&:focus {
						outline: none;
					}
					img {
						width: 100%;
					}
					&:nth-child(2) {
						margin-left: 1rem;
						img {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		.banner-top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media screen and (max-width: ${CUSTOM_TABLET}px) {
				flex-direction: column;
				align-items: flex-start;
			}

			&__left {
				.pDefault {
					font-family: 'Nunito', Arial, Helvetica, sans-serif;
					font-style: normal;
					font-weight: normal;
					font-size: 1.313rem;
					line-height: 150%;
					color: #ffffff;
					span {
						font-weight: bold;
					}
				}
				.h1Default {
					margin-top: 0.75rem !important;
				}
			}
		}

		.change-lang-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 2.813rem;
			padding-bottom: 1.813rem;
			button {
				background-color: transparent;
				border: none;
				&:hover {
					outline: none;
				}
			}
			.pDefault {
				color: #066fc2;
			}
		}
	}
`;

export default Wrapper;
