/* eslint-disable react/no-danger */
/* eslint-disable func-names */
/* eslint-disable no-var */
import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import H1 from '../../atoms/Label/Headers/H1';
import H3 from '../../atoms/Label/Headers/H3/Clear';
import Paragraph from '../../atoms/Label/Paragraph/Clear';

// Set the date we're counting down to
const countDownDate = new Date('Jun 15, 2021 23:59:59').getTime();

const Banner = ({ onSetExpire, isExpire }) => {
	const [hari, setHari] = useState(0);
	const [jam, setJam] = useState(0);
	const [menit, setMenit] = useState(0);
	const [detik, setDetik] = useState(0);
	// Update the count down every 1 second
	var x = setInterval(() => {
		// Get today's date and time
		const now = new Date().getTime();

		// Find the distance between now and the count down date
		const distance = countDownDate - now;

		// Time calculations for days, hours, minutes and seconds
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		setHari(days);
		setJam(hours);
		setMenit(minutes);
		setDetik(seconds);

		// If the count down is over, write some text
		if (distance < 0) {
			clearInterval(x);
			// document.getElementById('countdown').innerHTML = 'EXPIRED';
			onSetExpire(true);
		}
	}, 1000);

	const intl = useIntl();

	const renderTime = () => {
		if (isExpire) {
			return (
				<div className="card mx-1 bgCoundownt expired">
					<div className="bgCoundownt__expiretitle">
						{intl.formatMessage({ id: `promo_3_month.clock.expire.title` })}
					</div>
					<Paragraph className="pDefault bgCoundownt__expiresub">
						{intl.formatMessage({ id: `promo_3_month.clock.expire.sub` })}
					</Paragraph>
				</div>
			);
		}

		return (
			<>
				<div className="card mx-1 bgCoundownt">
					<div className="card-body">
						<H3 id="hari" className="h3Default card-title text-center h3">
							{hari}
						</H3>
						<Paragraph className="pDefault card-text text-center">
							{intl.formatMessage({ id: `promo_3_month.clock.day` })}
						</Paragraph>
					</div>
				</div>
				<div className="card mx-1 bgCoundownt">
					<div className="card-body">
						<H3 id="jam" className="h3Default card-title text-center h3">
							{jam}
						</H3>
						<Paragraph className="pDefault card-text text-center">
							{intl.formatMessage({ id: `promo_3_month.clock.hours` })}
						</Paragraph>
					</div>
				</div>
				<div className="card mx-1 bgCoundownt">
					<div className="card-body">
						<H3 id="menit" className="h3Default card-title text-center h3">
							{menit}
						</H3>
						<Paragraph className="pDefault card-text text-center">
							{intl.formatMessage({ id: `promo_3_month.clock.minutes` })}
						</Paragraph>
					</div>
				</div>
				<div className="card mx-1 bgCoundownt">
					<div className="card-body">
						<H3 id="detik" className="h3Default card-title text-center h3">
							{detik}
						</H3>
						<Paragraph className="pDefault card-text text-center">
							{intl.formatMessage({ id: `promo_3_month.clock.sec` })}
						</Paragraph>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="container-fluid">
			<div
				className="row cblueChatbiz supergraphicOverlay"
				style={{ paddingTop: `${7}em`, paddingBottom: `${7}em` }}
			>
				<div className="container">
					<div className="banner-top">
						<div className="banner-top__left text-light">
							<Paragraph>
								<div
									dangerouslySetInnerHTML={{
										__html: intl.formatMessage({ id: `promo_3_month.banner.sub_1` }),
									}}
								/>

								{intl.formatMessage({ id: `promo_3_month.banner.sub_2` })}
							</Paragraph>
							<H1 className="h1Default whiteColor">
								{' '}
								{intl.formatMessage({ id: `promo_3_month.banner.title` })}
							</H1>
						</div>
						<div className="banner-top__right text-light ">
							<div id="countdown" className="float-right d-inline-flex">
								{renderTime()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Banner.propTypes = {
	onSetExpire: PropTypes.func.isRequired,
	isExpire: PropTypes.bool.isRequired,
};
export default Banner;
