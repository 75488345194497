import React, { useEffect, useState } from 'react';
import { useIntl, changeLocale } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import ButtonPrimary from '../../atoms/Button/Primary';
import H2 from '../../atoms/Label/Headers/H2';

import Paragraph from '../../atoms/Label/Paragraph/Clear';
import SimpleSlider from './SimpleSlider';

export const Content = ({ enPrefix, onRegister, isExpire }) => {
	const intl = useIntl();
	const [currentLocation, setCurrentLocation] = useState(undefined);

	useEffect(() => {
		const detectLocation = typeof window !== 'undefined' ? window.location.pathname : '';
		setCurrentLocation(detectLocation);
	}, []);

	return (
		<>
			<div className="container">
				<div className="bg-white text-dark p-5" id="contentMain">
					<div className="content__cta">
						<div className="content__cta-left">
							<H2>{intl.formatMessage({ id: `promo_3_month.content.top.title` })}</H2>
							<Paragraph>{intl.formatMessage({ id: `promo_3_month.content.top.sub` })}</Paragraph>
						</div>
						<div className="content__cta-right">
							<ButtonPrimary id="mainCtaBtn" onClick={onRegister} isDisable={isExpire}>
								{isExpire ? (
									<Paragraph>{intl.formatMessage({ id: `promo_3_month.clock.expire.button` })}</Paragraph>
								) : (
									<Paragraph>{intl.formatMessage({ id: `promo_3_month.content.top.button` })}</Paragraph>
								)}
							</ButtonPrimary>
						</div>
					</div>

					<div className="content">
						<div className="content__left">
							<H2 className="h2Default my-4">
								{' '}
								{intl.formatMessage({ id: `promo_3_month.content.benefit.title` })}
							</H2>
							<div className="list-wrapper">
								<div className="list-wrapper__left">
									<ul className="list-unstyled">
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_1` })}
												</Paragraph>
											</div>
										</li>
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_2` })}
												</Paragraph>
											</div>
										</li>
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_3` })}
												</Paragraph>
											</div>
										</li>
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_4` })}
												</Paragraph>
											</div>
										</li>
									</ul>
								</div>
								<div className="list-wrapper__right">
									<ul className="list-unstyled">
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_5` })}
												</Paragraph>
											</div>
										</li>
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_6` })}
												</Paragraph>
											</div>
										</li>
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_7` })}
												</Paragraph>
											</div>
										</li>
										<li>
											<img src="https://seiturju.sirv.com/Chatbiz/v4/ico-checked-green.svg" alt="Fitur" />
											<div className="media-body d-inline-flex listbf" style={{ paddingLeft: `${18}px` }}>
												<Paragraph className="pDefault mt-0 mb-1">
													{intl.formatMessage({ id: `promo_3_month.content.benefit.list_8` })}
												</Paragraph>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div id="bf-down">
								<div className="bf-down__left">
									<img
										className="sclist"
										src="https://seiturju.sirv.com/Chatbiz/homepage/whatsapp.svg"
										alt="WhatsApp"
									/>
									<img
										className="sclist"
										src="https://seiturju.sirv.com/Chatbiz/homepage/line.svg"
										alt="Line"
									/>
									<img
										className="sclist"
										src="https://seiturju.sirv.com/Chatbiz/homepage/instagram.svg"
										alt="Instagram"
									/>
									<img
										className="sclist"
										src="https://seiturju.sirv.com/Chatbiz/homepage/telegram.svg"
										alt="Telegram"
									/>
									<img
										className="sclist"
										src="https://seiturju.sirv.com/Chatbiz/homepage/messenger.svg"
										alt="Messenger"
									/>
								</div>
								<div className="bf-down__right">
									<div id="lastbf">
										<Paragraph>{intl.formatMessage({ id: `promo_3_month.content.platform` })}</Paragraph>
									</div>
								</div>
							</div>
						</div>

						<div className="content__right">
							<H2 className="h2Default my-4">
								{intl.formatMessage({ id: `promo_3_month.content.trusted.title` })}
							</H2>
							<Paragraph>{intl.formatMessage({ id: `promo_3_month.content.trusted.sub` })}</Paragraph>
							<div id="logo-client">
								<SimpleSlider />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="change-lang-wrapper">
				<button
					type="button"
					onClick={() => {
						if (enPrefix) {
							window.location.href = `${currentLocation.replace('/en', '')}?lang=id`;
						} else {
							changeLocale('en');
						}
					}}
				>
					<Paragraph> {intl.formatMessage({ id: `promo_3_month.switch_lang` })}</Paragraph>
				</button>
			</div>
		</>
	);
};

Content.propTypes = {
	enPrefix: PropTypes.string.isRequired,
	onRegister: PropTypes.func.isRequired,
	isExpire: PropTypes.bool.isRequired,
};

export default Content;
